@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


/* Text With Gradient */
.title-gradient {
  background: -webkit-linear-gradient(#000, #353535);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.title-gradient-big {
  background: -webkit-linear-gradient(#000, #555);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient {
background: linear-gradient(#F3F5F7, #FFFAFA)
}

.pricing-gradient {
  background: linear-gradient(#F3F5F7, #FFFEFE);
  @media screen and (max-width: 750px) {
    background: #fff;
    
  }
}

.pricing-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.most-popular-shadow {
  box-shadow: 1px 3px 7px #fd9090;
}

.price-text-gradient{
  background: -webkit-linear-gradient(#AE1F23, #B97274);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/*************************** Handling the scrollbar ***************************/

/* width */
::-webkit-scrollbar {
  width: 7px;
position: relative;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* #laptop {
  animation-duration: 3s;
  animation-name: laptop;
}

@keyframes laptop {
  50% {
    transform: translateX(-1000%);
  }
  100%{
    transform: translateX(-100%);
  }
  
} */
.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun,sans-serif;
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
  content: "*";
}

